export default {
    namespaced: true,
    state: {
        gaugeId: null // 当前操作的量表的id
    },
    mutations: {
        // 新增完成后，将后端返回的量表的id进行替换
        changeGaugeId(state,payload){
            console.log(payload);
            state.gaugeId = payload;
        }
    },
    actions: {},
    modules: {}
}